.homepage {
  padding: 60px 0;

  .section-title {
    margin: 0;

    [data-title] {
      @include fontSize(38px, 120%);

      max-width: 100%;
      color: $gray900;
      font-weight: 600;
      text-align: left;

      @include phone {
        @include fontSize(24px, 130%);
      }
    }

    [data-description] {
      @include fontSize(20px, 150%);

      color: $black-alpha;
      text-align: left;

      @include phone {
        @include fontSize(16px, 150%);
      }
    }
  }

  .section-header {
    @include flex(row, space-between, flex-start);

    margin: 0 auto 48px;
    padding: 0 16px;
    max-width: $wide-content-width;

    @include phone {
      margin: 0 auto 24px;
    }
  }

  .navigation {
    @include flex(row, center, center);

    gap: 12px;
  }

  .navigation-button {
    @include flex(row, center, center);

    width: 56px;
    height: 56px;
    border: 1px solid $gray100;
    border-radius: 50%;
    background-color: $gray50;
  }

  .container {
    @include flex(row, center, center);

    width: 100%;
  }

  .sliderContainer {
    @include flex(row);

    width: $default-content-width;
    gap: 32px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(.swiper-wrapper) {
    align-items: initial !important;
    height: 100% !important;
  }

  @include phone {
    padding: 30px 0;
  }
}
