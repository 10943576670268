@import 'styles/utils/sizes';
@import 'styles/utils/mixins';
@import 'styles/utils/colors';

.card {
  width: 100%;
  background-color: $gray900;
  border-radius: 24px;
  box-sizing: border-box;
  height: 100%;

  .card-body {
    @include flex(column, space-between);

    @include macbook-thirteen {
      padding: 40px 32px;
    }

    gap: 16px;
    padding: 40px 80px;
    height: 100%;

    @include phone {
      padding: 16px;
    }
  }

  .card-text {
    @include fontSize(24px, 130%);

    flex-grow: 1;
    color: $gray0;

    p {
      margin: 0;
    }

    @include phone {
      @include fontSize(16px, 130%);
    }
  }

  .quote {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);

    path {
      fill: $yellow200;
    }
  }

  .person {
    @include flex(row, flex-start, center);

    width: fit-content;
    gap: 16px;
    padding: 8px 32px 8px 8px;
    background-color: $gray800;
    border-radius: 1000px;

    @include phone {
      width: 100%;
    }
  }

  .fullName {
    margin: 0;
    color: $gray0;
  }

  .position {
    @include fontSize(14px, 100%);

    margin: 0;
    color: #ffffffa3;

    * {
      color: #ffffffa3;
      text-decoration: none;
    }
  }

  .avatar {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
  }
}
